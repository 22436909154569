import axios, {AxiosResponse, AxiosError} from 'axios';
export const Single = {
  namespaced: true,
  state: {
    loaded: false,
    data: null,
    isError: null,
  },
  getters: {
    loaded: (state: any) => {
      return state.loaded;
    },
    data: (state: any) => {
      return state.data;
    },
    token: (state: any, getters: any, rootState: any, rootGetters: any) => {
      return rootGetters['auth/token'];
    },
  },
  mutations: {
    resetData: (state: any) => {
      state.data = null;
      state.isError = false;
    },
    logout: (state: any) => {
      state.loaded = false;
      state.data = null;
      state.isError = false;
    },
    setLoaded: (state: any, bool: boolean) => {
      state.loaded = bool;
    },
    setData: (state: any, data: PostData) => {
      state.data = data;
    },
    setError: (state: any, bool: boolean) => {
      state.isError = bool;
    },
  },
  actions: {
    getEntry: async (cxt: any, params: any) => {
      const result = await axios({
        method: 'get',
        url: `/api/wp-json/31corp/v1/posts?cat=${params.category}&id=${params.id}`,
        headers: { Authorization: 'Bearer ' + cxt.getters.token},
      })
      .then( (response: AxiosResponse<ApiPost>) => {
        cxt.commit('setData', response.data.datas[0]);
        cxt.dispatch('head/updateTitle', response.data.datas[0].title, {root: true});
        cxt.commit('setLoaded', true);
        return response;
      })
      .catch( (e: AxiosError) => {
        console.log(e);
      });
      return result;
    },
  },
};
