
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import Loading from '@/components/loading/Loading.vue';
import store from '@/store';

@Component({
  components: {
    Loading,
  },
})
export default class App extends Vue {
  private defaultLayout: string = 'default';
  private loadingTimer: any = null;
  private showLoading: boolean = true;
  get layout(): string {
    return this.$route.meta.layout + '-layout';
  }
  get loaded(): boolean {
    return store.getters.loaded;
  }
  @Watch('loaded')
  private changeLoadedState(newVal: boolean) {
    if (newVal) {
      this.clearLoadingTimer();
    } else {
      this.setLoadingTimer();
    }
  }

  @Emit()
  private setLoadingTimer() {
    this.clearLoadingTimer();
    this.loadingTimer = setTimeout(() => {
      this.showLoading = true;
    }, 600);
  }

  @Emit()
  private clearLoadingTimer() {
    this.showLoading = false;
    clearTimeout(this.loadingTimer);
  }

  private mounted() {
    if (this.loaded) {
      this.clearLoadingTimer();
    } else {
      this.setLoadingTimer();
    }

    //RewardsClub統合対応
    function goGardenhotelsModal() {
      const DOC = document;
      const MODAL_NAME = 'go-gardenhotels-modal';
      const BODY = DOC.body;
      const modalHtml = `
      <div class="go-gardenhotels-modal" id="go-gardenhotels-modal">
        <div class="go-gardenhotels-modal__wrap" role="dialog" aria-modal="true" aria-labelledby="go-gardenhotels-modal__label">
          <div class="go-gardenhotels-modal__overlay" tabindex="-1"></div>
          <div class="go-gardenhotels-modal__inner">
            <div class="go-gardenhotels-modal__content">
              <p class="go-gardenhotels-modal__label" id="go-gardenhotels-modal__label">
                今月より、MGH Rewards Club 連携特典を併用いただけます！
                <button type="button" class="go-gardenhotels-modal__close">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABnklEQVRYhe3ZP2vCQBzG8Sdn6Z68BMHF2ZfTgkvxT0IFoSVLZ6EFcZCQoVvfjbpmMYkvIRk7Rcsv9QRDojR3xKPcF5wM8iF3l5yJsdls7gF8AHgAYEGNUgBfAF7uALwDeFYExjOPpm8G4FENU2lPTKFhLcti6pnO00DRNFA0DRTt/wIPh4NcSUW1gKvVCv1+H2maXj2WjhmNRthut80A9/s9lsslgiDAcDi8iKTvbNvGer3GfD5vBsgYw2KxQLvdRhzHlUiOozPX6XQwm82aAVKWZcH3/RNyMBicIYs4z/NgmmZzwCJyt9udkPQZj8dScBTtqIWWY5Ik+TATkrCtVgthGErBSQEWkZQsHGRdqA3DyM8cL8syGT+bJwzkc44Pa3FO3hRYxNGwli2cmwDLcDTnqlZ3o0B++6parTKRtYCu6yKKoourtYicTCa1Nhi1gNPpFL1e7+qlhCO73S4cx8lX+1+Tch28Fm0w6B5ep0Y2rHVx0Ft+CWmgaBoomgaKRsBEYV/Cjk/TVe2TnvK/0j35+DBd/E+EnH5fQwBvP6me58YZbHgaAAAAAElFTkSuQmCC" alt="閉じる" width="20" height="20" loading="lazy">
                </button>
              </p>
              <div class="go-gardenhotels-modal__body">
                <p class="go-gardenhotels-modal__text go-gardenhotels-modal__attention">
                  MGH Rewards Club会員登録を完了されている方は、<br>予約画面上でログイン後、ご予約ください。
                </p>
                <div class="go-gardenhotels-modal-img">
                  <img src="/imgs/20240131_MGHRC_popup.png" alt="バナー画像" width="350" height="160" loading="lazy">
                </div>
                <p class="go-gardenhotels-modal__text">
                  <a href="https://corp.gardenhotels.co.jp/mgh-rewards-club/" target="_blank" rel="noreferrer">MGH Rewards Club会員登録はこちらから</a>
                </p>
                <p class="go-gardenhotels-modal__text">
                  <a href="/pages/2024mghrec" target="_blank" rel="noreferrer">MGH Rewards Club 連携や<br>アプリのダウンロードについてはこちらから</a>
                </p>
                <a href="" target="_blank" rel="noreferrer" class="go-gardenhotels-modal__link">
                  予約する
                </a>
                <p class="go-gardenhotels-modal__text-sub">
                  ※ MGH Rewards Club 会員登録を行わないで<br>予約する場合もこちらからお進みください。

                </p>
                <a href="/specialoffer/75483" rel="noreferrer" class="go-gardenhotels-modal__bnr">
                  <img src="/imgs/20240109_MGHRC_popup.png" alt="バナー画像" width="350" height="160" loading="lazy" class="go-gardenhotels-modal__bnr-img">
                </a>
                <button type="button" class="go-gardenhotels-modal__close-btn">
                  閉じる
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>`;

      DOC.addEventListener('click', function(e) {
        if (store.getters['auth/lang'] !== 'ja') {
          return true;
        }

        // クリックされた要素が <a> タグであるか確認
        const target = e.target;
        if (target instanceof HTMLElement && !target.closest(`#${MODAL_NAME}`) && target.tagName === 'A') {
          const href = target.getAttribute('href');
          if (href !== null && href.startsWith('https://go-gardenhotels.reservation.jp/')) {
            e.preventDefault();

            BODY.insertAdjacentHTML('beforeend', modalHtml);

            const modal = DOC.getElementById(MODAL_NAME);
            if(modal === null) {
              return;
            }

            //リンク設定
            const modalLink = modal.querySelector(`.${MODAL_NAME}__link`);
            if(modalLink instanceof HTMLElement) {
              modalLink.setAttribute('href', href);
              modalLink.dataset.gasendsite = target.dataset.gasendsite;
              modalLink.addEventListener('click', function() {
                modal.remove();
              });
            }

            //close
            const modalCloseItems = modal.querySelectorAll(`.${MODAL_NAME}__overlay,.${MODAL_NAME}__close,.${MODAL_NAME}__close-btn`);
            modalCloseItems.forEach(function(item) {
              item.addEventListener('click', function() {
                modal.remove();
              });
            });

          }
        }
      });
    }
    goGardenhotelsModal();
  }
}
