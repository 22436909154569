
import { Component, Mixins, Emit } from 'vue-property-decorator';
import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '@/store';
import Home from '@/mixins/Home';
import HomeMainVisual from '@/components/home/HomeMainVisual/HomeMainVisual.vue';
import HomeSlider from '@/components/home/HomeSlider/HomeSlider.vue';
import NaviSerchnavi from '@/components/navi/NaviSerchnavi/NaviSerchnavi.vue';
import HomeFavoritelist from '@/components/home/HomeFavoritelist/HomeFavoritelist.vue';
import HomeCampaignlist from '@/components/home/HomeCampaignlist/HomeCampaignlist.vue';
import HomeNewslist from '@/components/home/HomeNewslist/HomeNewslist.vue';
import HomePickuplist from '@/components/home/HomePickuplist/HomePickuplist.vue';
import HomeHistory from '@/components/home/HomeHistory/HomeHistory.vue';
import HomeSns from '@/components/home/HomeSNS/HomeSNS.vue';
import HomeSpecialoffer from '@/components/home/HomeSpecialoffer/HomeSpecialoffer.vue';
import ButtonTodayLowestPrice from '@/components/button/ButtonTodayLowestPrice.vue';
import ButtonToday from '@/mixins/ButtonToday';
import ButtonLineMini from '@/components/button/ButtonLineMini.vue';
import ButtonRewardsClub from '@/components/button/ButtonRewardsClub.vue';
import $ from 'jquery';
@Component({
  components: {
    HomeMainVisual,
    HomeSlider,
    NaviSerchnavi,
    HomeFavoritelist,
    HomeCampaignlist,
    HomeNewslist,
    HomePickuplist,
    HomeHistory,
    HomeSns,
    HomeSpecialoffer,
    ButtonTodayLowestPrice,
    ButtonLineMini,
    ButtonRewardsClub,
  },
})
export default class HomeJa extends Mixins(Home, ButtonToday) {
  private firstBanner: boolean = false;
  private popupBanner = null;
  get account(): string {
    return store.getters['auth/account'];
  }
  get newsCancelUrl(): string {
    return process.env.VUE_APP_NEWS_CANCEL_URL;
  }
  @Emit()
  private clickTodayBanner() {
    window.gtag('event', 'CrntFunction', {event_category: 'todayHotel', event_label: 'todayHotel02'});
  }

  get token(): boolean {
    return this.$store.getters['auth/token'];
  }

  private created() {
    const group = this.$store.getters['auth/group'];
    const whitelist = [
      'subscriber',
      'subscriber02',
      'subscriber03',
      'subscriber04',
      'subscriber05',
      'subscriber06',
      'subscriber07',
      'subscriber08',
      'subscriber09',
      'subscriber12',
      'subscriber14',
      'subscriber15',
      'subscriber16',
    ];
    if (!sessionStorage.getItem('first-banner') && whitelist.includes(group)) {
      let headers: {Authorization?: string} = {};
      if (headers) {
        headers = { Authorization: 'Bearer ' + this.token};
      }
      axios.get(`/api/wp-json/31corp/v1/banners?cat=popup-banner`, {headers})
      .then((res: AxiosResponse) => {
        if (res.data.length > 0) {
          const max = res.data.length;
          const min = 0;
          const ran = Math.floor(Math.random() * ( (max) - min ) + min);
          this.popupBanner = res.data[ran];
          sessionStorage['first-banner'] = '1';
        }
      });
    }
  }
  private async mounted() {
    const refs: any = this.$refs;
    const arr = [];
    if (refs.slider) {
      arr.push(refs.slider.getEntries());
    }
    if (refs.favoritelist) {
      arr.push(refs.favoritelist.getEntries());
    }
    if (refs.campaignlist) {
      arr.push(refs.campaignlist.getEntries());
    }
    if (refs.newslist) {
      arr.push(refs.newslist.getEntries());
    }
    if (refs.pickuplist) {
      arr.push(refs.pickuplist.getEntries());
    }
    if (refs.historylist) {
      arr.push(refs.historylist.getEntries());
    }
    if (refs.specialoffer) {
      arr.push(refs.specialoffer.getEntries());
    }
    await Promise.all(arr);
    this.loaded = true;
    store.commit('loaded', true);

    $('.topBanner .close, .topBanner .bg').on('click', function(ev) {
      ev.preventDefault();
      $('.topBanner').fadeOut(function() {
        $('.topBanner').remove();
      });
    });
  }
}
