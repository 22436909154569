
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class BoxSpecialofferLink extends Vue {
  @Prop({ default: null })
  private offer!: SpecialofferItems|null;

  get hasNewIcon() {
    return (this.offer && this.offer.newIcon);
  }

  mounted() {
    console.log(this.offer)
  }
}
