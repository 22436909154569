
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
// @ is an alias to /src
import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '@/store';
import BoxSpecialofferLink from '@/components/box/BoxSpecialofferLink/BoxSpecialofferLink.vue';

@Component({
  components: {
    BoxSpecialofferLink,
  },
})
export default class HomeSpecialoffer extends Vue {
  private name = 'specialoffer';
  private offers: SpecialofferItems[]|null = null;
  get companyName(): string {
    return store.getters['auth/name'];
  }
  get show(): boolean {
    if (this.offers) {
      return this.offers.length > 0 ? true : false;
    } else {
      return false;
    }
  }
  @Emit()
  private setData(data: Specialoffer): void {
    this.offers = data.offers;
  }
  @Emit()
  private async getEntries() {
    const result = await axios({
      method: 'get',
      url: `/api/wp-json/31corp/v1/special_offer`,
      headers: { Authorization: 'Bearer ' + this.$store.getters['auth/token']},
    })
    .then( (response: AxiosResponse<Specialoffer>) => {
      this.setData(response.data);
      return true;
    })
    .catch( (e: AxiosError) => {
      console.log(e);
      return false;
    });
    return result;
  }
}
